

.title {

  img {
    margin-bottom: calc(-.08 * var(--titlecontents-width));
  }

  &__subtitle {
    font-family: 'Arimo', sans-serif;
    margin-top: calc(-.05 * var(--titlecontents-width));
  }
}
